import { configureStore } from "@reduxjs/toolkit";
import registeringCompanyReducer from "./registeringCompanySlice";
import sharedReducer from "./sharedSlice";
import businessReducer from "./businessSlice";
import homeReducer from "./homeSlice";
import registeringHomeReducer from "./registeringHomeSlice";
import userReducer from "./userPanelSlice";
import adminReducer from "./adminSlice";
import contactUsSliceReducer from "./contactUsSlice";

const store = configureStore({
  reducer: {
    registeringCompany: registeringCompanyReducer,
    shared: sharedReducer,
    business: businessReducer,
    home: homeReducer,
    registeringHome: registeringHomeReducer,
    user: userReducer,
    admin: adminReducer,
    contactUs: contactUsSliceReducer,
  },
});

export default store;
