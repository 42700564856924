export const countyMapping = {
  Stockholm: ["Stockholm", "Stockholms län", "capital"],
  Göteborg: ["Göteborg", "Västra Götalands län", "Gothenburg"],
  Norrbotten: ["Norrbotten", "Norrbottens län"],
  Västerbotten: ["Västerbotten", "Västerbottens län"],
  Jämtland: ["Jämtland", "Jämtlands län"],
  Västernorrland: ["Västernorrland", "Västernorrlands län"],
  Gävleborg: ["Gävleborg", "Gävleborgs län"],
  Dalarna: ["Dalarna", "Dalarnas län"],
  Värmland: ["Värmland", "Värmlands län"],
  Örebro: ["Örebro", "Örebro län"],
  Västmanland: ["Västmanland", "Västmanlands län"],
  Uppsala: ["Uppsala", "Uppsala län"],
  Södermanland: ["Södermanland", "Södermanlands län"],
  Skaraborg: ["Skaraborg"],
  Östergötland: ["Östergötland", "Östergötlands län"],
  Älvsborg: ["Älvsborg"],
  Jönköping: ["Jönköping", "Jönköpings län"],
  Kalmar: ["Kalmar", "Kalmar län"],
  Gotland: ["Gotland", "Gotlands län"],
  Halland: ["Halland", "Hallands län"],
  Kronoberg: ["Kronoberg", "Kronobergs län"],
  Blekinge: ["Blekinge", "Blekinge län"],
  Skåne: ["Skåne", "Skåne län", "Scania"],
};

export const findCounty = (inputString) => {
  for (const [county, aliases] of Object.entries(countyMapping)) {
    if (aliases.includes(inputString)) {
      return county;
    }
  }
  return null;
};
