import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import "firebase/database";
import Axios from "axios";
import ApiStatus from "../Farsi/Shared/Constants/apiStatus";

const initialState = {
  apiStatus: {
    postingContactus: ApiStatus.ApiIdle,
  },
};

export const postContactUsMessage = createAsyncThunk(
  "postContactUsMessage",
  async (data) => {
    try {
      const res = await Axios.post(
        "https://app.bazarma.se/api/v1/user/contact_us",
        {
          name: data.name,
          mobile: data.tel,
          email: data.email,
          message: data.text,
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error posting data: ", error);
      throw error;
    }
  }
);

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    resetContactUsState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postContactUsMessage.pending, (state) => {
        state.apiStatus.postingContactus = ApiStatus.ApiStarting;
      })
      .addCase(postContactUsMessage.fulfilled, (state) => {
        state.apiStatus.postingContactus = ApiStatus.ApiDone;
      })
      .addCase(postContactUsMessage.rejected, (state) => {
        state.apiStatus.postingContactus = ApiStatus.ApiFailed;
      });
  },
});
export const { resetContactUsState } = contactUsSlice.actions;
export default contactUsSlice.reducer;
