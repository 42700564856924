import React, { useState } from "react";
import { Container, HeaderCtr, BackBtn } from "./TransferOwnership.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../design-system";
import { Helmet } from "react-helmet";
import { adminTransferOwnership } from "../../../store/adminSlice";
import ApiStatus from "../../Shared/Constants/apiStatus";

const TransferOwnership = () => {
  const [telNumber, setTelNumber] = useState("");
  const [companyId, setCompanyId] = useState("");
  const apiStatus = useSelector((state) => state.admin.apiStatus);
  const dispatch = useDispatch();

  const transfer = () => {
    dispatch(adminTransferOwnership({ telNumber, companyId }));
  };

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <HeaderCtr>
        <Link to="/admin-sv-fa">
          <BackBtn>Back!</BackBtn>
        </Link>
      </HeaderCtr>

      <Container>
        {apiStatus.adminTransferOwnership === ApiStatus.ApiDone && (
          <span> ارسال شد</span>
        )}
        {apiStatus.adminTransferOwnership === ApiStatus.ApiFailed && (
          <span> خطا</span>
        )}
        <input
          type="text"
          placeholder="company id for transfering"
          value={companyId}
          onChange={(e) => setCompanyId(e.target.value)}
          style={{
            padding: "8px",
            width: "100%",
            maxWidth: "400px",
            height: "40px",
          }}
        />
        <input
          type="text"
          placeholder="tel number for transfering"
          value={telNumber}
          onChange={(e) => setTelNumber(e.target.value)}
          style={{
            padding: "8px",
            width: "100%",
            maxWidth: "400px",
            height: "40px",
          }}
        />
        <Button mb={"20px"} onClick={() => transfer()}>
          Transfer Ownership
        </Button>
      </Container>
    </div>
  );
};

export default TransferOwnership;
