import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import "firebase/database";
import Axios from "axios";
import ApiStatus from "../Farsi/Shared/Constants/apiStatus";

const initialState = {
  selectedCategory: "",
  selectedCity: "",
  searchInputText: "",
  allCompanies: [],
  apiStatus: {
    fetchingAllCompanies: ApiStatus.ApiIdle,
    fetchingSelectedCompany: ApiStatus.ApiIdle,
  },
  selectedCompany: {},
};

export const fetchSelectedCompany = createAsyncThunk(
  "business/fetchSelectedCompany",
  async (id) => {
    try {
      const res = await Axios.get(
        `https://app.bazarma.se/api/v1/company/get_company/${id}`
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

export const fetchAllCompanies = createAsyncThunk(
  "business/fetchAllCompanies",
  async () => {
    try {
      const res = await Axios.get(
        "https://app.bazarma.se/api/v1/company/list_companies"
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setSelectedCity(state, action) {
      state.selectedCity = action.payload;
    },
    setSearchInputText(state, action) {
      state.searchInputText = action.payload;
    },
    setAllAdvertisments(state, action) {
      state.allCompanies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompanies.pending, (state) => {
        state.apiStatus.fetchingAllCompanies = ApiStatus.ApiStarting;
      })
      .addCase(fetchAllCompanies.fulfilled, (state, action) => {
        state.allCompanies = action.payload;
        state.apiStatus.fetchingAllCompanies = ApiStatus.ApiDone;
      })
      .addCase(fetchAllCompanies.rejected, (state, action) => {
        state.error = action.payload;
        state.apiStatus.fetchingAllCompanies = ApiStatus.ApiFailed;
      });
    builder
      .addCase(fetchSelectedCompany.pending, (state) => {
        state.apiStatus.fetchingSelectedCompany = ApiStatus.ApiStarting;
      })
      .addCase(fetchSelectedCompany.fulfilled, (state, action) => {
        state.selectedCompany = action.payload;
        state.apiStatus.fetchingSelectedCompany = ApiStatus.ApiDone;
      })
      .addCase(fetchSelectedCompany.rejected, (state, action) => {
        state.error = action.payload;
        state.apiStatus.fetchingSelectedCompany = ApiStatus.ApiFailed;
      });
  },
});

export const {
  setSelectedCategory,
  setSelectedCity,
  setSearchInputText,
  setAllAdvertisments,
} = businessSlice.actions;

export default businessSlice.reducer;
