import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import constVars from "../Farsi/Shared/constvars";
import { loginStatus } from "../Farsi/Shared/Constants/loginStatus";
import Cookies from "js-cookie";
import { normalizePhoneNumber } from "../Farsi/Shared/Helpers/normalizePhoneNumber";
import * as Sentry from "@sentry/react";
import ApiStatus from "../Farsi/Shared/Constants/apiStatus";

const initialState = {
  allAds: [],
  dashboardPage: "",
  apiStatus: {
    sendOpt: ApiStatus.ApiIdle,
    login: ApiStatus.ApiIdle,
    fetchUser: ApiStatus.ApiIdle,
    fetchUserHomeAds: ApiStatus.ApiIdle,
    fetchUserCompaniesAds: ApiStatus.ApiIdle,
    deleteCompanyAd: ApiStatus.ApiIdle,
  },
  status: loginStatus.none,
  isUserLoggedin: false,
  userData: {
    name: "",
    email: "",
    mobile: "",
  },
  userCompaniesAds: [],
  userHomeAds: [],
};

export const requestVerificationNumber = createAsyncThunk(
  "requestVerificationNumber",
  async (mobileNumber) => {
    try {
      const res = await Axios.post(
        "https://app.bazarma.se/api/v1/auth/send_opt",
        {
          mobile: normalizePhoneNumber(mobileNumber),
        }
      );

      return res.error;
    } catch (error) {
      console.error("Error fetching data: ", error);
      Sentry.captureException(error);
    }
  }
);

export const login = createAsyncThunk(
  "login",
  async ({ mobileNumber, code }, { rejectWithValue }) => {
    try {
      const res = await Axios.post("https://app.bazarma.se/api/v1/auth/login", {
        mobile: normalizePhoneNumber(mobileNumber),
        opt: code.toString(),
      });

      if (res.status !== 200 || res.data.error) {
        throw new Error(res.data.error || "Login failed");
      }

      Cookies.set("jwt-token", res.data.access_token);

      return res.data;
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue(
        error.response?.data || "An error occurred during login"
      );
    }
  }
);

export const getUser = createAsyncThunk("getUser", async () => {
  try {
    const token = Cookies.get("jwt-token");
    const res = await Axios.get("https://app.bazarma.se/api/v1/auth/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
});

export const fetchUserCompaniesAds = createAsyncThunk(
  "fetchUserCompaniesAds",
  async () => {
    try {
      const token = Cookies.get("jwt-token");
      const res = await Axios.get(
        "https://app.bazarma.se/api/v1/company/get_user_companies",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const fetchUserHomeAds = createAsyncThunk(
  "fetchUserHomeAds",
  async () => {
    try {
      const token = Cookies.get("jwt-token");
      const res = await Axios.get(
        "https://app.bazarma.se/api/v1/home/get_user_homes",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

export const deleteHomeAd = createAsyncThunk("deleteHomeAd", async (adId) => {
  try {
    const token = Cookies.get("jwt-token");
    const res = await Axios.delete(
      `https://app.bazarma.se/api/v1/home/delete_home/${adId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
});

export const deleteCompanyAd = createAsyncThunk(
  "deleteCompanyAd",
  async (adId) => {
    try {
      const token = Cookies.get("jwt-token");
      const res = await Axios.delete(
        `https://app.bazarma.se/api/v1/company/delete_company/${adId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

const userPanelSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserAllAds(state, action) {
      state.allAds = action.payload;
    },
    setUserDashboardPage(state, action) {
      state.dashboardPage = action.payload;
    },
    updateUserStatus(state, action) {
      state.status = action.payload;
    },
    setIsUserLoggedIn(state, action) {
      state.isUserLoggedin = action.payload;
    },
    resetUserSliceState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestVerificationNumber.pending, (state) => {
        state.apiStatus.sendOpt = ApiStatus.ApiStarting;
      })
      .addCase(requestVerificationNumber.fulfilled, (state) => {
        state.status = loginStatus.pendingForDigits;
        state.apiStatus.sendOpt = ApiStatus.ApiDone;
      })
      .addCase(requestVerificationNumber.rejected, (state) => {
        state.apiStatus.sendOpt = ApiStatus.ApiFailed;
      });
    builder
      .addCase(login.pending, (state) => {
        state.apiStatus.login = ApiStatus.ApiStarting;
      })
      .addCase(login.fulfilled, (state) => {
        state.status = loginStatus.success;

        state.isUserLoggedin = true;
        state.apiStatus.login = ApiStatus.ApiDone;
      })
      .addCase(login.rejected, (state) => {
        state.status = loginStatus.failed;
        state.isUserLoggedin = false;
        state.apiStatus.login = ApiStatus.ApiFailed;
      });
    builder
      .addCase(getUser.pending, (state) => {
        state.apiStatus.fetchUser = ApiStatus.ApiStarting;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isUserLoggedin = true;
        state.userData.email = action.payload.email;
        state.userData.mobile = action.payload.mobile;
        state.userData.name = action.payload.name;
        state.apiStatus.fetchUserUser = ApiStatus.ApiDone;
      })
      .addCase(getUser.rejected, (state) => {
        state.status = getUser.failed;
        state.apiStatus.fetchUser = ApiStatus.ApiFailed;
      });

    builder
      .addCase(fetchUserCompaniesAds.pending, (state) => {
        state.apiStatus.fetchUserCompaniesAds = ApiStatus.ApiStarting;
      })
      .addCase(fetchUserCompaniesAds.fulfilled, (state, action) => {
        state.apiStatus.fetchUserCompaniesAds = ApiStatus.ApiDone;
        state.userCompaniesAds = action.payload.map((ad) => ({
          ...ad,
          type: constVars.BusinessEnv,
        }));
      })
      .addCase(fetchUserCompaniesAds.rejected, (state) => {
        state.apiStatus.fetchUserCompaniesAds = ApiStatus.ApiFailed;
      });
    builder
      .addCase(fetchUserHomeAds.pending, (state) => {
        state.apiStatus.fetchUserHomeAds = ApiStatus.ApiStarting;
      })
      .addCase(fetchUserHomeAds.fulfilled, (state, action) => {
        state.apiStatus.fetchUserHomeAds = ApiStatus.ApiDone;
        state.userHomeAds = action.payload.map((ad) => ({
          ...ad,
          type: constVars.HomeEnv,
        }));
      })
      .addCase(fetchUserHomeAds.rejected, (state) => {
        state.apiStatus.fetchUserHomeAds = ApiStatus.ApiFailed;
      });

    builder
      .addCase(deleteHomeAd.pending, (state) => {
        state.apiStatus.deleteHomeAd = ApiStatus.ApiStarting;
      })
      .addCase(deleteHomeAd.fulfilled, (state) => {
        state.apiStatus.deleteHomeAd = ApiStatus.ApiDone;
      })
      .addCase(deleteHomeAd.rejected, (state) => {
        state.apiStatus.deleteHomeAd = ApiStatus.ApiFailed;
      });
    builder
      .addCase(deleteCompanyAd.pending, (state) => {
        state.apiStatus.deleteCompanyAd = ApiStatus.ApiStarting;
      })
      .addCase(deleteCompanyAd.fulfilled, (state) => {
        state.apiStatus.deleteCompanyAd = ApiStatus.ApiDone;
      })
      .addCase(deleteCompanyAd.rejected, (state) => {
        state.apiStatus.deleteCompanyAd = ApiStatus.ApiFailed;
      });
  },
});

export const {
  setUserAllAds,
  resetUserSliceState,
  setUserDashboardPage,
  updateUserStatus,
  setIsUserLoggedIn,
} = userPanelSlice.actions;

export default userPanelSlice.reducer;
