import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import Cookies from "js-cookie";
import ApiStatus from "../Farsi/Shared/Constants/apiStatus";
import { normalizePhoneNumber } from "../Farsi/Shared/Helpers/normalizePhoneNumber";

const initialState = {
  adminIsLoggedin: false,
  marketing: {
    allSms: [],
  },
  ads: {
    business: {
      allCompanies: [],
      approvedCompanies: [],
      unApprovedCompanies: [],
    },
    homes: {
      allHomes: [],
      approvedHomes: [],
      unApprovedHomes: [],
    },
    contactUs: {
      allMessages: [],
    },
  },
  apiStatus: {
    adminLogin: ApiStatus.ApiIdle,
    adminGetAllCompanies: ApiStatus.ApiIdle,
    adminGetApprovedCompanies: ApiStatus.ApiIdle,
    adminGetApprovedHomes: ApiStatus.ApiIdle,
    adminGetUnApprovedCompanies: ApiStatus.ApiIdle,
    adminGetUnApprovedHomes: ApiStatus.ApiIdle,
    adminGetAllMarketingSms: ApiStatus.ApiIdle,
    adminApproveCompany: ApiStatus.ApiIdle,
    adminApproveHome: ApiStatus.ApiIdle,
    adminDeleteCompany: ApiStatus.ApiIdle,
    adminDeleteHome: ApiStatus.ApiIdle,
    adminEditCompany: ApiStatus.ApiIdle,
    adminEditHome: ApiStatus.ApiIdle,
    adminGetAllContactUsMessages: ApiStatus.ApiIdle,
    adminSendMarketingSms: ApiStatus.ApiIdle,
    adminTransferOwnership: ApiStatus.ApiIdle,
  },
};

export const loginAdmin = createAsyncThunk(
  "admin/loginAdmin",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const res = await Axios.post(
        `https://app.bazarma.se/api/admin/auth/login`,
        null,
        {
          params: {
            email: email,
            password: password,
          },
        }
      );
      Cookies.set("jwt-token-admin", res.data.access_token);
      return res.access_token;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const adminGetAllContactUsMessages = createAsyncThunk(
  "admin/adminGetAllContactUsMessages",
  async () => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.get(
        `https://app.bazarma.se/api/admin/contact_us/get_contact_us`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

export const adminGetAllCompanies = createAsyncThunk(
  "admin/adminGetAllCompanies",
  async () => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.get(
        `https://app.bazarma.se/api/admin/company/get_all_companies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const adminGetApprovedCompanies = createAsyncThunk(
  "admin/adminGetApprovedCompanies",
  async () => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.get(
        `https://app.bazarma.se/api/admin/company/get_approved_companies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const adminGetApprovedHomes = createAsyncThunk(
  "admin/adminGetApprovedHomes",
  async () => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.get(
        `https://app.bazarma.se/api/admin/home/get_approved_homes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const adminGetUnApprovedCompanies = createAsyncThunk(
  "admin/adminGetUnApprovedCompanies",
  async () => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.get(
        `https://app.bazarma.se/api/admin/company/get_unapproved_companies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const adminGetUnApprovedHomes = createAsyncThunk(
  "admin/adminGetUnApprovedHomes",
  async () => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.get(
        `https://app.bazarma.se/api/admin/home/get_unapproved_homes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const adminGetAllMarketingSms = createAsyncThunk(
  "admin/adminGetAllMarketingSms",
  async () => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.get(
        `https://app.bazarma.se/api/admin/marketing/get_all_marketing_sms`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

export const adminApproveCompany = createAsyncThunk(
  "admin/adminApproveCompany",
  async (id) => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.post(
        `https://app.bazarma.se/api/admin/company/approve_company/${id}`,
        {
          approval: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const adminSendMarketingSms = createAsyncThunk(
  "admin/adminSendMarketingSms",
  async ({ tel, message, note, category }) => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.post(
        `https://app.bazarma.se/api/admin/marketing/send_sms`,
        {
          phone_number: tel,
          message: message,
          note: note,
          category: category,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const adminTransferOwnership = createAsyncThunk(
  "admin/adminTransferOwnership",
  async ({ telNumber, companyId }) => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.post(
        `https://app.bazarma.se/api/admin/company_ownership_transfer/transfer_ownership/${companyId}`,
        {
          new_owner_mobile: normalizePhoneNumber(telNumber),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

export const adminApproveHome = createAsyncThunk(
  "admin/adminApproveHome",
  async (id) => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.post(
        `https://app.bazarma.se/api/admin/home/approve_home/${id}`,
        {
          approval: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

export const adminDeleteCompany = createAsyncThunk(
  "admin/adminDeleteCompany",
  async (id) => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.delete(
        `https://app.bazarma.se/api/admin/company/delete_company/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const adminDeleteHome = createAsyncThunk(
  "admin/adminDeleteHome",
  async (id) => {
    try {
      const token = Cookies.get("jwt-token-admin");
      const res = await Axios.delete(
        `https://app.bazarma.se/api/admin/home/delete_home/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);
export const adminEditCompany = createAsyncThunk(
  "adminEditCompany",
  async (_, { getState }) => {
    try {
      const state = getState().registeringCompany;

      const token = Cookies.get("jwt-token-admin");

      const res = await Axios.put(
        `https://app.bazarma.se/api/admin/company/update_company/${state.id}`,
        {
          title: state.title,
          address: state.address,
          addressLat: state.addressLat,
          addressLng: state.addressLng,
          category: state.category,
          county: state.city,
          description: state.details,
          discount: {
            has_discount: state.discount.hasDiscount,
            percentage: state.discount.discountPercentage,
            start_date: state.discount.discountStartDate,
            end_date: state.discount.discountEndDate,
          },
          email: state.email,
          media: {
            website: state.media.website,
            instagram: state.media.instagram,
            facebook: state.media.facebook,
          },
          image_url: state.downloadedImagesLinks,
          main_image: state.downloadedMainImageLink,
          telephone: state.telephone,
          source: "website",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw error;
    }
  }
);
export const adminEditHome = createAsyncThunk(
  "adminEditHome",
  async (_, { getState }) => {
    try {
      const state = getState().registeringHome;

      const token = Cookies.get("jwt-token-admin");

      const res = await Axios.put(
        `https://app.bazarma.se/api/admin/home/update_home/${state.id}`,
        {
          renting_part: state.rentingPart,
          address: {
            lat: state.address.lat,
            lng: state.address.lng,
            house_number: state.address.houseNumber,
            street: state.address.street,
            postal_code: state.address.postalCode,
            county: state.address.county,
            city: state.address.city,
            area: state.address.area,
          },
          rooms: state.rooms,
          deposit: state.deposit,
          interior: state.interior,
          rent_home_type: state.rentHomeType,
          size: state.size,
          rent_start_date: state.rentStartDate,
          rent_end_date: state.rentEndDate,
          tenant: state.tenant,
          pets_allowed: state.pets,
          smoking_allowed: state.smoking,
          image_urls: state.downloadedImagesLinks,
          main_image: state.downloadedMainImageLink,
          price: state.price,
          description: state.description,
          telephone: state.telephone,
          email: state.email,
          source: "website",

          consent: {
            necessary: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw error;
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminLoggedIn(state, action) {
      state.adminIsLoggedin = action.payload;
    },
    resetAdminState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state) => {
        state.apiStatus.adminLogin = ApiStatus.ApiStarting;
      })
      .addCase(loginAdmin.fulfilled, (state) => {
        state.adminIsLoggedin = true;
        state.apiStatus.adminLogin = ApiStatus.ApiDone;
      })
      .addCase(loginAdmin.rejected, (state) => {
        state.apiStatus.adminLogin = ApiStatus.ApiFailed;
        state.adminIsLoggedin = false;
      });

    builder
      .addCase(adminGetAllCompanies.pending, (state) => {
        state.apiStatus.adminGetAllCompanies = ApiStatus.ApiStarting;
      })
      .addCase(adminGetAllCompanies.fulfilled, (state, action) => {
        state.ads.business.allCompanies = action.payload;
        state.adminIsLoggedin = true;
        state.apiStatus.adminGetAllCompanies = ApiStatus.ApiDone;
      })
      .addCase(adminGetAllCompanies.rejected, (state) => {
        state.apiStatus.adminGetAllCompanies = ApiStatus.ApiFailed;
        state.adminIsLoggedin = false;
      });
    builder
      .addCase(adminGetApprovedCompanies.pending, (state) => {
        state.apiStatus.adminGetApprovedCompanies = ApiStatus.ApiStarting;
      })
      .addCase(adminGetApprovedCompanies.fulfilled, (state, action) => {
        state.ads.business.approvedCompanies = action.payload;
        state.adminIsLoggedin = true;
        state.apiStatus.adminGetApprovedCompanies = ApiStatus.ApiDone;
      })
      .addCase(adminGetApprovedCompanies.rejected, (state) => {
        state.apiStatus.adminGetApprovedCompanies = ApiStatus.ApiFailed;
        state.adminIsLoggedin = false;
      });
    builder
      .addCase(adminGetApprovedHomes.pending, (state) => {
        state.apiStatus.adminGetApprovedHomes = ApiStatus.ApiStarting;
      })
      .addCase(adminGetApprovedHomes.fulfilled, (state, action) => {
        state.ads.homes.approvedHomes = action.payload;
        state.adminIsLoggedin = true;
        state.apiStatus.adminGetApprovedHomes = ApiStatus.ApiDone;
      })
      .addCase(adminGetApprovedHomes.rejected, (state) => {
        state.apiStatus.adminGetApprovedHomes = ApiStatus.ApiFailed;
        state.adminIsLoggedin = false;
      });

    builder
      .addCase(adminGetUnApprovedCompanies.pending, (state) => {
        state.apiStatus.adminGetUnApprovedCompanies = ApiStatus.ApiStarting;
      })
      .addCase(adminGetUnApprovedCompanies.fulfilled, (state, action) => {
        state.ads.business.unApprovedCompanies = action.payload;
        state.adminIsLoggedin = true;
        state.apiStatus.adminGetUnApprovedCompanies = ApiStatus.ApiDone;
      })
      .addCase(adminGetUnApprovedCompanies.rejected, (state) => {
        state.apiStatus.adminGetUnApprovedCompanies = ApiStatus.ApiFailed;
        state.adminIsLoggedin = false;
      });
    builder
      .addCase(adminGetUnApprovedHomes.pending, (state) => {
        state.apiStatus.adminGetUnApprovedHomes = ApiStatus.ApiStarting;
      })
      .addCase(adminGetUnApprovedHomes.fulfilled, (state, action) => {
        state.ads.homes.unApprovedHomes = action.payload;
        state.adminIsLoggedin = true;
        state.apiStatus.adminGetUnApprovedHomes = ApiStatus.ApiDone;
      })
      .addCase(adminGetUnApprovedHomes.rejected, (state) => {
        state.apiStatus.adminGetUnApprovedHomes = ApiStatus.ApiFailed;
        state.adminIsLoggedin = false;
      });
    builder
      .addCase(adminGetAllMarketingSms.pending, (state) => {
        state.apiStatus.adminGetAllMarketingSms = ApiStatus.ApiStarting;
      })
      .addCase(adminGetAllMarketingSms.fulfilled, (state, action) => {
        state.marketing.allSms = action.payload;
        state.adminIsLoggedin = true;
        state.apiStatus.adminGetAllMarketingSms = ApiStatus.ApiDone;
      })
      .addCase(adminGetAllMarketingSms.rejected, (state) => {
        state.apiStatus.adminGetAllMarketingSms = ApiStatus.ApiFailed;
        state.adminIsLoggedin = false;
      });
    builder
      .addCase(adminApproveCompany.pending, (state) => {
        state.apiStatus.adminApproveCompany = ApiStatus.ApiStarting;
      })
      .addCase(adminApproveCompany.fulfilled, (state) => {
        state.apiStatus.adminApproveCompany = ApiStatus.ApiDone;
      })
      .addCase(adminApproveCompany.rejected, (state) => {
        state.apiStatus.adminApproveCompany = ApiStatus.ApiFailed;
      });
    builder
      .addCase(adminSendMarketingSms.pending, (state) => {
        state.apiStatus.adminSendMarketingSms = ApiStatus.ApiStarting;
      })
      .addCase(adminSendMarketingSms.fulfilled, (state) => {
        state.apiStatus.adminSendMarketingSms = ApiStatus.ApiDone;
      })
      .addCase(adminSendMarketingSms.rejected, (state) => {
        state.apiStatus.adminSendMarketingSms = ApiStatus.ApiFailed;
      });
    builder
      .addCase(adminTransferOwnership.pending, (state) => {
        state.apiStatus.adminTransferOwnership = ApiStatus.ApiStarting;
      })
      .addCase(adminTransferOwnership.fulfilled, (state) => {
        state.apiStatus.adminTransferOwnership = ApiStatus.ApiDone;
      })
      .addCase(adminTransferOwnership.rejected, (state) => {
        state.apiStatus.adminTransferOwnership = ApiStatus.ApiFailed;
      });
    builder
      .addCase(adminApproveHome.pending, (state) => {
        state.apiStatus.adminApproveHome = ApiStatus.ApiStarting;
      })
      .addCase(adminApproveHome.fulfilled, (state) => {
        state.apiStatus.adminApproveHome = ApiStatus.ApiDone;
      })
      .addCase(adminApproveHome.rejected, (state) => {
        state.apiStatus.adminApproveHome = ApiStatus.ApiFailed;
      });
    builder
      .addCase(adminDeleteCompany.pending, (state) => {
        state.apiStatus.adminDeleteCompany = ApiStatus.ApiStarting;
      })
      .addCase(adminDeleteCompany.fulfilled, (state) => {
        state.apiStatus.adminDeleteCompany = ApiStatus.ApiDone;
      })
      .addCase(adminDeleteCompany.rejected, (state) => {
        state.apiStatus.adminDeleteCompany = ApiStatus.ApiFailed;
      });
    builder
      .addCase(adminDeleteHome.pending, (state) => {
        state.apiStatus.adminDeleteHome = ApiStatus.ApiStarting;
      })
      .addCase(adminDeleteHome.fulfilled, (state) => {
        state.apiStatus.adminDeleteHome = ApiStatus.ApiDone;
      })
      .addCase(adminDeleteHome.rejected, (state) => {
        state.apiStatus.adminDeleteHome = ApiStatus.ApiFailed;
      });
    builder
      .addCase(adminEditCompany.pending, (state) => {
        state.apiStatus.adminEditCompany = ApiStatus.ApiStarting;
      })
      .addCase(adminEditCompany.fulfilled, (state) => {
        state.apiStatus.adminEditCompany = ApiStatus.ApiDone;
      })
      .addCase(adminEditCompany.rejected, (state) => {
        state.apiStatus.adminEditCompany = ApiStatus.ApiFailed;
      });
    builder
      .addCase(adminEditHome.pending, (state) => {
        state.apiStatus.adminEditHome = ApiStatus.ApiStarting;
      })
      .addCase(adminEditHome.fulfilled, (state) => {
        state.apiStatus.adminEditHome = ApiStatus.ApiDone;
      })
      .addCase(adminEditHome.rejected, (state) => {
        state.apiStatus.adminEditHome = ApiStatus.ApiFailed;
      });
    builder
      .addCase(adminGetAllContactUsMessages.pending, (state) => {
        state.apiStatus.adminGetAllContactUsMessages = ApiStatus.ApiStarting;
      })
      .addCase(adminGetAllContactUsMessages.fulfilled, (state, action) => {
        state.ads.contactUs.allMessages = action.payload;
        state.adminIsLoggedin = true;
        state.apiStatus.adminGetAllContactUsMessages = ApiStatus.ApiDone;
      })
      .addCase(adminGetAllContactUsMessages.rejected, (state) => {
        state.apiStatus.adminGetAllContactUsMessages = ApiStatus.ApiFailed;
        state.adminIsLoggedin = false;
      });
  },
});

export const { setAdminLoggedIn, resetAdminState } = adminSlice.actions;
export default adminSlice.reducer;
