import React, { useEffect, Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import constVars from "./Farsi/Shared/constvars";
import NoMatchPage from "./noMatchPage";

import { setEnvironment } from "./store/sharedSlice";
import TransferOwnership from "./Farsi/Admin/TransferOwnership/TransferOwnership";

const AboutusSwedenFarsi = React.lazy(() =>
  import("./Farsi/Bazar/AboutUs/about-us")
);
const PrivacyPolicy = React.lazy(() =>
  import("./Farsi/Bazar/PrivacyPolicy/privacypolicy")
);
const FaqSwedenFarsi = React.lazy(() => import("./Farsi/Bazar/Faq/faq"));
const Admin = React.lazy(() => import("./Farsi/Admin/admin"));
const AdminApprovedCompanies = React.lazy(() =>
  import("./Farsi/Admin/Companies/AdminApprovedCompanies")
);
const AdminApprovedHomes = React.lazy(() =>
  import("./Farsi/Admin/Home/AdminApprovedHomes")
);
const AdminUnApprovedCompanies = React.lazy(() =>
  import("./Farsi/Admin/Companies/AdminUnApprovedCompanies")
);
const AdminUnApprovedHomes = React.lazy(() =>
  import("./Farsi/Admin/Home/AdminUnApprovedHomes")
);
const ContactusSwedenFarsi = React.lazy(() =>
  import("./Farsi/Bazar/Contactus/contactus")
);
const EditingCompany = React.lazy(() =>
  import("./Farsi/UserPanel/Editing/editingCompany")
);
const AdminContactUsMessages = React.lazy(() =>
  import("./Farsi/Admin/ContactUs/AdminContactUsMessages")
);
const PanelSms = React.lazy(() => import("./Farsi/Admin/PanelSms/PanelSms"));
const HomeAds = React.lazy(() => import("./Farsi/Home/Homes/homes"));
const RegisterHome = React.lazy(() =>
  import("./Farsi/Home/Registering/registerHome")
);

const SwedenFarsi = React.lazy(() => import("./homeSwedenFarsi"));
const RegisterCompany = React.lazy(() =>
  import("./Farsi/Business/Registering/registerCompany")
);
const Business = React.lazy(() =>
  import("./Farsi/Business/Businesses/allCompanies")
);
const UserPanel = React.lazy(() => import("./Farsi/UserPanel/userPanel"));
const SelectedCompanyPage = React.lazy(() =>
  import("./Farsi/Business/Businesses/CompanyPage/selectedCompanyPage")
);
const SelectedHomePage = React.lazy(() =>
  import("./Farsi/Home/SelectedHomePage/selectedHomePage")
);

const EditingHome = React.lazy(() =>
  import("./Farsi/UserPanel/Editing/editingHome")
);

function AppRoutes() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname.includes("companies") ||
      location.pathname.includes("/company/") ||
      location.pathname.includes("register-company")
    ) {
      dispatch(setEnvironment(constVars.BusinessEnv));
    } else if (location.pathname.includes("find-home")) {
      dispatch(setEnvironment(constVars.HomeEnv));
    } else if (location.pathname.includes("register-home")) {
      dispatch(setEnvironment(constVars.HomeCreateAdEnv));
    } else if (location.pathname.includes("/editing-company/")) {
      dispatch(setEnvironment(constVars.BusinessEditingEnv));
    } else if (location.pathname.includes("/editing-home/")) {
      dispatch(setEnvironment(constVars.HomeEditingEnv));
    } else if (location.pathname.includes("/admin-editing-home/")) {
      dispatch(setEnvironment(constVars.AdminHomeEditingEnv));
    } else if (location.pathname.includes("/admin-editing-company/")) {
      dispatch(setEnvironment(constVars.AdminBusinessEditingEnv));
    } else if (
      location.pathname === "/sv-fa" ||
      location.pathname === "/user-panel" ||
      location.pathname === "/"
    ) {
      dispatch(setEnvironment(""));
    }
  }, [location, dispatch]);

  return (
    <React.Fragment>
      <Switch>
        <Route
          path="/"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <SwedenFarsi />
            </Suspense>
          )}
        />
        <Route
          path="/register-company"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <RegisterCompany />
            </Suspense>
          )}
        />
        <Route
          path="/register-home"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <RegisterHome />
            </Suspense>
          )}
        />
        <Route
          path="/sv-fa"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <SwedenFarsi />
            </Suspense>
          )}
        />
        <Route
          path="/find-home"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <HomeAds environment={constVars.HomeEnv} />
            </Suspense>
          )}
        />
        <Route
          path="/aboutus-sv-fa"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <AboutusSwedenFarsi />
            </Suspense>
          )}
        />
        <Route
          path="/privacy-policy"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <PrivacyPolicy />
            </Suspense>
          )}
        />
        <Route
          path="/FAQ-sv-fa"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <FaqSwedenFarsi />
            </Suspense>
          )}
        />
        <Route
          path="/admin-sv-fa"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <Admin />
            </Suspense>
          )}
        />
        <Route
          path="/admin-sv-fa/panel-sms"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <PanelSms />
            </Suspense>
          )}
        />
        <Route
          path="/admin-sv-fa/transfer-ownership"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <TransferOwnership />
            </Suspense>
          )}
        />
        <Route
          path="/admin-sv-fa/approved-home-ads"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <AdminApprovedHomes />
            </Suspense>
          )}
        />
        <Route
          path="/admin-sv-fa/unapproved-home-ads"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <AdminUnApprovedHomes />
            </Suspense>
          )}
        />
        <Route
          path="/admin-sv-fa/approved-companies"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <AdminApprovedCompanies />
            </Suspense>
          )}
        />
        <Route
          path="/admin-sv-fa/unapproved-companies"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <AdminUnApprovedCompanies />
            </Suspense>
          )}
        />

        <Route
          path="/admin-sv-fa/contactus-messages"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <AdminContactUsMessages />
            </Suspense>
          )}
        />
        <Route
          path="/contactUs-sv-fa"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <ContactusSwedenFarsi />
            </Suspense>
          )}
        />
        <Route
          path="/companies"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <Business environment={constVars.BusinessEnv} />
            </Suspense>
          )}
        />
        <Route
          path="/user-panel"
          exact
          render={() => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <UserPanel />
            </Suspense>
          )}
        />
        <Route
          path="/company/:id/:slug"
          exact
          render={(props) => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <SelectedCompanyPage {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/home/:id"
          exact
          render={(props) => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <SelectedHomePage {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/editing-home/:id"
          exact
          render={(props) => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <EditingHome {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/admin-editing-home/:id"
          exact
          render={(props) => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <EditingHome {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/editing-company/:id/:slug"
          exact
          render={(props) => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <EditingCompany {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/admin-editing-company/:id/:slug"
          exact
          render={(props) => (
            <Suspense fallback={<div>در حال بارگذاری</div>}>
              <EditingCompany {...props} />
            </Suspense>
          )}
        />

        <Route path="*" component={NoMatchPage} />
      </Switch>
    </React.Fragment>
  );
}

export default AppRoutes;
