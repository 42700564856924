import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import Cookies from "js-cookie";
import { storage } from "../firebase";
import { normalizePhoneNumber } from "../Farsi/Shared/Helpers/normalizePhoneNumber";
import ApiStatus from "../Farsi/Shared/Constants/apiStatus";

function getFormattedDate(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Months are zero-based, so add 1
  let day = date.getDate();
  return `${year}-${month}-${day}`;
}

function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const initialState = {
  category: "",
  registerCompanyStep: 1,
  address: "",
  addressLng: "",
  addressLat: "",
  city: "",
  email: "",
  telephone: [""],

  media: {
    website: "",
    instagram: "",
    facebook: "",
  },
  title: "",
  details: "",
  discount: {
    hasDiscount: false,
    discountStartDate: getFormattedDate(new Date()),
    discountEndDate: getFormattedDate(addDays(new Date(), 10)),
    discountPercentage: 0,
    dateError: false,
  },
  images: [],
  downloadedImagesLinks: [],
  downloadedMainImageLink: "",
  id: 0,
  isAdvertisementSent: false,

  apiStatus: {
    createCompanyAd: ApiStatus.ApiIdle,
    editCompanyAd: ApiStatus.ApiIdle,
    uploadImages: ApiStatus.ApiIdle,
  },
};

export const uploadCompanyImages = createAsyncThunk(
  "registerCompany/uploadCompanyImages",
  async (_, { getState, dispatch }) => {
    const state = getState().registeringCompany;
    const images = state.images;
    const dateObj = new Date();
    const newdate = `${dateObj.getUTCFullYear()}-${
      dateObj.getUTCMonth() + 1
    }-${dateObj.getUTCDate()}-${dateObj.getUTCHours()}-${dateObj.getUTCMinutes()}`;

    let galleryUrlLinks = [];

    const fileObjectsImages = images.filter((item) => item instanceof File);
    const stringObjectsImages = images.filter(
      (item) => typeof item === "string"
    );

    // Push strings directly to galleryUrlLinks
    galleryUrlLinks.push(...stringObjectsImages);

    // Upload files and get download URLs
    if (fileObjectsImages.length > 0) {
      const galleryImgUpload = await Promise.all(
        fileObjectsImages.map(async (pic) => {
          const filename = pic.name;
          const storageRef = storage.ref(`${newdate}/${filename}`);
          await storageRef.put(pic);
          return await storageRef.getDownloadURL();
        })
      );
      galleryUrlLinks.push(...galleryImgUpload);
    }
    dispatch(setCompanyDownloadedImages(galleryUrlLinks));
    dispatch(setCompanyDownloadedMainImage(galleryUrlLinks[0]));
    return {
      galleryUrlLinks,
    };
  }
);

export const createCompanyAd = createAsyncThunk(
  "createCompanyAd",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState().registeringCompany;

      const token = Cookies.get("jwt-token");
      const normalizedTelephones = state.telephone.map((phone) =>
        normalizePhoneNumber(phone)
      );
      const res = await Axios.post(
        "https://app.bazarma.se/api/v1/company/create_company",
        {
          title: state.title,
          address: state.address,
          addressLat: state.addressLat,
          addressLng: state.addressLng,
          category: state.category,
          county: state.city,
          description: state.details,
          discount: {
            has_discount: state.discount.hasDiscount,
            percentage: state.discount.discountPercentage,
            start_date: state.discount.discountStartDate,
            end_date: state.discount.discountEndDate,
          },
          email: state.email,
          media: {
            website: state.media.website,
            instagram: state.media.instagram,
            facebook: state.media.facebook,
          },
          image_url: state.downloadedImagesLinks,
          main_image: state.downloadedMainImageLink,
          telephone: normalizedTelephones,
          source: "website",
          consent: {
            necessary: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const editCompanyAd = createAsyncThunk(
  "editCompanyAd",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState().registeringCompany;

      const token = Cookies.get("jwt-token");
      const normalizedTelephones = state.telephone
        .filter((phone) => phone)
        .map((phone) => normalizePhoneNumber(phone));

      const res = await Axios.put(
        `https://app.bazarma.se/api/v1/company/update_company/${state.id}`,
        {
          title: state.title,
          address: state.address,
          addressLat: state.addressLat,
          addressLng: state.addressLng,
          category: state.category,
          county: state.city,
          description: state.details,
          discount: {
            has_discount: state.discount.hasDiscount,
            percentage: state.discount.discountPercentage,
            start_date: state.discount.discountStartDate,
            end_date: state.discount.discountEndDate,
          },
          email: state.email,
          media: {
            website: state.media.website,
            instagram: state.media.instagram,
            facebook: state.media.facebook,
          },
          image_url: state.downloadedImagesLinks,
          main_image: state.downloadedMainImageLink,
          telephone: normalizedTelephones,
          source: "website",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const registerCompanySlice = createSlice({
  name: "registerCompany",
  initialState,
  reducers: {
    setCompanyCategory(state, action) {
      state.category = action.payload;
    },
    setRegisterCompanyStep(state, action) {
      state.registerCompanyStep = action.payload;
    },
    setCompanyAddress(state, action) {
      state.address = action.payload;
    },
    setCompanyAddressLAT(state, action) {
      state.addressLat = action.payload;
    },
    setCompanyAddressLNG(state, action) {
      state.addressLng = action.payload;
    },
    setCompanyCity(state, action) {
      state.city = action.payload;
    },
    setCompanyEmail(state, action) {
      state.email = action.payload;
    },
    setCompanyTelephone(state, action) {
      const { index, normalizeNumber } = action.payload;
      state.telephone[index] = normalizeNumber;
    },
    removeCompanyTelephone(state, action) {
      const index = action.payload;
      state.telephone.splice(index, 1);
    },

    setCompanyWebsite(state, action) {
      state.media.website = action.payload;
    },
    setCompanySocialMediaFacebook(state, action) {
      state.media.facebook = action.payload;
    },
    setCompanySocialMediaInstagram(state, action) {
      state.media.instagram = action.payload;
    },
    setCompanyTitle(state, action) {
      state.title = action.payload;
    },
    setCompanyDetails(state, action) {
      state.details = action.payload;
    },
    setCompanyDiscountPercentage(state, action) {
      state.discount.discountPercentage = action.payload;
    },
    setUploadImagesIdle(state) {
      state.apiStatus.uploadImages = ApiStatus.ApiIdle;
    },
    setCompanyHasDiscount(state, action) {
      state.discount.hasDiscount = action.payload;
    },
    setCompanyDiscountStartDate(state, action) {
      state.discount.discountStartDate = action.payload;
    },
    setCompanyDiscountEndDate(state, action) {
      state.discount.discountEndDate = action.payload;
    },
    setCompanyDiscountDateError(state, action) {
      state.discount.dateError = action.payload;
    },
    setCompanyImages(state, action) {
      state.images = action.payload;
    },
    setCompanyMainImage(state, action) {
      state.mainImage = action.payload;
    },
    setCompanyDownloadedImages(state, action) {
      state.downloadedImagesLinks = action.payload;
    },
    setCompanyDownloadedMainImage(state, action) {
      state.downloadedMainImageLink = action.payload;
    },
    setCompanyId(state, action) {
      state.id = action.payload;
    },
    setIsAdvertisementSent(state, action) {
      state.isAdvertisementSent = action.payload;
    },
    resetStateRegisteringCompany() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCompanyAd.pending, (state) => {
        state.apiStatus.createCompanyAd = ApiStatus.ApiStarting;
      })
      .addCase(createCompanyAd.fulfilled, (state) => {
        state.apiStatus.createCompanyAd = ApiStatus.ApiDone;
      })
      .addCase(createCompanyAd.rejected, (state) => {
        state.apiStatus.createCompanyAd = ApiStatus.ApiFailed;
      });
    builder
      .addCase(editCompanyAd.pending, (state) => {
        state.apiStatus.editCompanyAd = ApiStatus.ApiStarting;
      })
      .addCase(editCompanyAd.fulfilled, (state) => {
        state.apiStatus.editCompanyAd = ApiStatus.ApiDone;
      })
      .addCase(editCompanyAd.rejected, (state) => {
        state.apiStatus.editCompanyAd = ApiStatus.ApiFailed;
      });
    builder

      .addCase(uploadCompanyImages.pending, (state) => {
        state.apiStatus.uploadImages = ApiStatus.ApiStarting;
      })
      .addCase(uploadCompanyImages.fulfilled, (state) => {
        state.apiStatus.uploadImages = ApiStatus.ApiDone;
      })
      .addCase(uploadCompanyImages.rejected, (state) => {
        state.apiStatus.uploadImages = ApiStatus.ApiFailed;
      });
  },
});

export const {
  setCompanyCategory,
  setRegisterCompanyStep,
  setCompanyAddress,
  setCompanyAddressLAT,
  setCompanyAddressLNG,
  setCompanyCity,
  setCompanyEmail,
  setCompanyTelephone,
  removeCompanyTelephone,
  setCompanyWebsite,
  setCompanySocialMediaFacebook,
  setCompanySocialMediaInstagram,
  setCompanyTitle,
  setCompanyDetails,
  setCompanyDiscountPercentage,
  setCompanyHasDiscount,
  setCompanyDiscountStartDate,
  setCompanyDiscountEndDate,
  setCompanyDiscountDateError,
  setCompanyImages,
  setCompanyMainImage,
  setCompanyDownloadedImages,
  setCompanyDownloadedMainImage,
  setCompanyId,
  setIsAdvertisementSent,
  resetStateRegisteringCompany,
  setUploadImagesIdle,
} = registerCompanySlice.actions;

export default registerCompanySlice.reducer;
