const constVars = {
  BusinessEnv: "خدمات",
  HomeEnv: "خانه",
  BusinessEditingEnv: "ویرایش خدمات",
  HomeEditingEnv: "ویرایش خانه",
  HomeCreateAdEnv: "ثبت خانه",
  AdminBusinessEditingEnv: "ویرایش خدمات ادمین",
  AdminHomeEditingEnv: "ویرایش خانه ادمین",
};

export default constVars;
